import React from "react";
import styled from "styled-components";
import Grid from "@tleef/react-grid";
import showdown from "showdown";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { rhythm } from "../utils/typography";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Content from "../components/Content";
import MediaBar from "../components/MediaBar";

const converter = new showdown.Converter();

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Article = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;

const Title = styled.div`
  color: ${(props) => props.theme.color.secondary.main};
  display: inline-flex;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  padding-bottom: 10px;
  line-height: 1;
  border-bottom: 1px solid ${(props) => props.theme.color.secondary.main};
`;

const Body = styled.div`
  color: ${(props) => props.theme.color.secondary.minus};

  & > p:last-child {
    margin-bottom: 0;
  }
`;

const Section = styled.div`
  margin-bottom: ${rhythm(1)};
`;

const Image = styled(Img)`
  float: right;
  border-radius: 2px;
  margin-left: ${rhythm(1)};
  margin-bottom: ${rhythm(1)};
  width: 40%;
`;

const section1 = converter.makeHtml(`# Building a technology startup is hard.

Hello! Welcome to [tleef.com](https://tleef.com)

You have probably thought at one time or another about starting your own business.

We have all heard the incredible stories of entrepreneurs like Mark Zuckerberg, Jeff Bezos, Elon Musk, and the list goes on and on, who have amassed incredible fortunes and changed the world with technology. At the same time, you are not oblivious to the risk. You know that most startups fail.

*Why is that?*

It's not because you need to be a genius to build a successful company.

The reason why most people do poorly in business boils down to two things: 

1. Lack of knowledge
2. Lack of discipline

Some people say business is like war. Others say it is like playing a game of Chess. One thing that all three have in common is this: 

> The more you know, the better you do.

It's easy to get swept up by the excitement of an idea and think that will be enough to carry you through the difficult parts of building a successful technology startup. That is why this story repeats itself time and again. You start your business  bursting with energy, everything is looking up. Then you are faced with some difficult decisions, you zig when maybe you should have zagged, money gets tight, morale is low, and the feeling of euphoria gets replaced by depression. The company is lost in a blink.

Building a successful startup combines enormous complexity with powerful emotions which makes it one of the most difficult and fascinating things that you can do. That's also why, for many people, being an entrepreneur *is* the American Dream.  

## Knowledge and discipline is the key to success.

If you are new to entrepreneurship and are looking for ways to better you chances of success, great news! I am too.`);

const section2 = converter.makeHtml(`I'm Thomas Lee a.k.a. "tleef" *(tea-leaf)*

I'm a software engineer who has always had a passion for building.

I was first introduced to computers at an early age by my father who helped me build my first computer. 

I have been a professional software engineer for many years and I was the Director of Platform Engineering at Tinder. Throughout my career I have watched as technology has evolved and business with it.

## To stay competitive in business you must be constantly learning.

That is why I have dedicated this blog to sharing my experiences and what I learn on this incredible journey.

We will dive deep into the technology that forms the foundation of your business as well as discuss high level strategy and phycology that will prepare you for whatever comes your way.

Join me on this adventure of learning and improving for greater success. Make sure to subscribe to the newsletter to receive all of the latest updates and feel free to reach out on social media or by email.`);

export default ({ data }) => {
  return (
    <Layout>
      <Seo
        title={"About"}
        url={`https://tleef.com/about`}
        keywords={[
          `blog`,
          `investing`,
          `trading`,
          `stocks`,
          `software`,
          `engineering`,
        ]}
      />
      <Container>
        <Header />
        <Content>
          <Grid container spacing={40}>
            <Grid item xs={12} md={8}>
              <Article>
                <TitleContainer>
                  <Title>About</Title>
                </TitleContainer>
                <Section>
                  <Body dangerouslySetInnerHTML={{ __html: section1 }} />
                </Section>
                <Section>
                  <Image fluid={data.profilePic.childImageSharp.fluid} />
                  <Body dangerouslySetInnerHTML={{ __html: section2 }} />
                </Section>
              </Article>
            </Grid>
            <Grid item xs={12} md={4}>
              <MediaBar aboutMe={false} />
            </Grid>
          </Grid>
        </Content>
        <Footer />
      </Container>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    profilePic: file(absolutePath: { regex: "/profile.png/" }) {
      childImageSharp {
        fluid(maxWidth: 480, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
